import { Component, Input, inject } from "@angular/core";
import { Source } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { Constants } from "src/app/constants/constants";
import { ChannelTypes } from "../../channel";

@Component({
    selector: "app-channel-stream",
    templateUrl: "./channel-stream.component.html"
})
export class ChannelStreamComponent {
    @Input() source: Source;
    @Input() channel: ChannelTypes;
    showOverlay = true;
    urls = Constants.urls;
    private deviceService = inject(DeviceDetectorService);
    canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
}
