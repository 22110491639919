<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit(form)" *ngIf="!loading">
    <ng-template #RestartContent>{{ "RESTART_REQUIRED" | translate }}</ng-template>

    <div class="title-bar">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8 col-lg-6">
                <div class="title">
                    <h1 *ngIf="!isClone && !isEdit">
                        {{ "CREATE_NEW_TRANSCODED_CHANNEL" | translate }}
                    </h1>
                    <h1 *ngIf="isClone">{{ "CLONE_TRANSCODED_CHANNEL" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT_TRANSCODED_CHANNEL" | translate }} - {{ channel.name }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <!-- Transcoded -->
            <div class="row justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">
                    <section id="info">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INFO' | translate }}">
                                {{ "INFO" | translate }}
                            </h3>
                        </div>
                        <!-- Name -->
                        <app-input-generic-field
                            zmid="form_name"
                            [isRestartRequiredTip]="isEdit"
                            [duplicateName]="channelNames"
                            [formControl]="nameControl"
                            [textFieldName]="'NAME' | translate"
                            [disabled]="saving"
                            [isParentFormSubmitted]="form.submitted"
                            type="text"
                        ></app-input-generic-field>

                        <!-- Access Tags -->
                        <zx-access-tags-select
                            [infoTooltip]="'TOOLTIP.CHANNEL_TAGS' | translate"
                            zmid="form_access-tags"
                            [formControl]="tagsControl"
                            [label]="'TAGS' | translate"
                            name="resource_tag_ids"
                            type="adaptive"
                            [isParentFormSubmitted]="form.submitted"
                        ></zx-access-tags-select>

                        <!-- Alerting Profile -->
                        <div class="form-group">
                            <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                                "ALERTING_PROFILE" | translate
                            }}</label>
                            <zx-alerting-profile-select
                                name="alertingProfile"
                                [(model)]="channel.alertingProfile"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                            ></zx-alerting-profile-select>
                        </div>
                    </section>
                    <section id="config">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                                {{ "CONFIGURATION" | translate }}
                            </h3>
                        </div>
                        <!-- Processing Cluster -->
                        <div class="form-group">
                            <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                                >{{ "PROCESSING_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><fa-icon icon="info-circle" [ngbTooltip]="PCContent" triggers="hover click" [closeDelay]="500"></fa-icon
                                ><ng-template #PCContent
                                    ><p class="mb-0">
                                        {{ "TOOLTIP.CHANNEL_PROCESSING_CLUSTER_TRANSCODED" | translate }}
                                    </p></ng-template
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-cluster-select
                                name="broadcaster_cluster_id"
                                [(model)]="channel.broadcaster_cluster_id"
                                [ngModel]="channel.broadcaster_cluster_id"
                                required="true"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                                (modelChange)="reloadBroadcasterTargets(channel.broadcaster_cluster_id)"
                            ></zx-cluster-select>
                            <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                    {{ "PROCESSING_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                                </div>
                            </div>
                        </div>
                        <!-- Target Broadcaster/s -->
                        <div class="form-group">
                            <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                                >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <ng-select
                                id="target_broadcaster_id"
                                name="target_broadcaster_id"
                                [items]="targetBXs"
                                [clearable]="false"
                                bindValue="id"
                                bindLabel="name"
                                groupBy="type"
                                placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                                [(ngModel)]="channel.broadcaster_id"
                                [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                                [disabled]="!channel.broadcaster_cluster_id || targetBXsLoading"
                                #targetBroadcasters="ngModel"
                                [loading]="targetBXsLoading"
                                required
                            >
                                <ng-template ng-optgroup-tmp let-item="item">
                                    <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                                </ng-template>
                                <ng-template ng-label-tmp let-item="item">
                                    <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                    <span *ngIf="!item.type">{{ item.name }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                    <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                    <span *ngIf="!item.type">{{ item.name }}</span>
                                </ng-template>
                            </ng-select>
                            <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                                <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <!-- Source -->
                        <div class="form-group">
                            <label
                                for="transcodingSource"
                                [ngClass]="{ 'is-invalid': form.submitted && (form.controls.transcodingSource?.errors || transcodingSource?.disable_autopull) }"
                                >{{ "SOURCE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <zx-source-select
                                name="transcodingSource"
                                [(model)]="transcodingSourceId"
                                [(ngModel)]="transcodingSourceId"
                                required="true"
                                (modelChange)="transcodingSourceChanged(transcodingSourceId)"
                                [ngClass]="{ 'is-invalid': form.submitted && (form.controls.transcodingSource?.errors || transcodingSource?.disable_autopull) }"
                                [filter]="uniqueSourceFilter()"
                            ></zx-source-select>
                            <div *ngIf="form.controls.transcodingSource?.invalid" class="invalid-feedback">
                                <div *ngIf="form.controls.transcodingSource?.errors.required">{{ "SOURCE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                            <div *ngIf="form.submitted && transcodingSource?.disable_autopull" class="invalid-feedback">
                                <div>{{ "AUTO_PULL_REQUIRED_FOR_TRANSCODED" | translate }}.</div>
                            </div>
                            <small *ngIf="transcodingSourceId && getSourceBroadcasterClusterID(transcodingSourceId) !== channel.broadcaster_cluster_id"
                                >{{ "THE_SOURCE_WILL_BE_AUTOMATICALLY_SENT_TO_THE_SELECTED_PROCESSING_BROADCASTER" | translate }}.</small
                            >
                        </div>
                        <!-- Alternative Channel -->
                        <div class="form-group">
                            <label
                                for="alternative_channel_select"
                                [ngClass]="{ 'is-invalid': form.submitted && form.controls.alternative_channel_select?.errors }"
                                >{{ "ALTERNATIVE_CHANNEL" | translate }} ({{ "DISASTER_RECOVERY" | translate }})</label
                            >
                            <zx-adaptive-channel-select
                                id="alternative_channel_select"
                                name="alternative_channel_select"
                                [model]="channel.alt_channel_id || null"
                                [filterFunction]="alternativeChannelFilter"
                                (modelChange)="channel.alt_channel_id = $event"
                                [clearable]="true"
                            ></zx-adaptive-channel-select>
                        </div>
                        <!-- Include Source (mutual exclusive with Slate Source) -->
                        <div class="form-group">
                            <div class="input-group align-items-center">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="is_source_included"
                                                name="is_source_included"
                                                [(ngModel)]="channel.is_source_included"
                                                (change)="mutuallyExclusiveSources(false)"
                                            />
                                            <label class="form-check-label" for="is_source_included"
                                                >{{ "INCLUDE_SOURCE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-div">
                                    <input
                                        type="number"
                                        id="source_bitrate"
                                        name="source_bitrate"
                                        placeholder="{{ 'BITRATE' | translate }} [kbps]"
                                        class="form-control"
                                        [disabled]="!channel.is_source_included"
                                        [readonly]="!channel.is_source_included"
                                        required="channel.is_source_included"
                                        [(ngModel)]="transcodedSourceBitrate"
                                        #source_bitrate="ngModel"
                                        [ngClass]="{ 'is-invalid': form.submitted && source_bitrate.errors, disabled: source_bitrate.disabled }"
                                    />
                                    <div *ngIf="source_bitrate.invalid" class="invalid-feedback">
                                        <div *ngIf="source_bitrate.errors.required">{{ "BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Slate Source (mutual exclusive with include source) -->
                        <div class="form-group">
                            <div class="input-group align-items-center">
                                <div class="input-group-prepend">
                                    <div class="input-group">
                                        <div class="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                class="form-check-input"
                                                id="is_slate_source"
                                                name="is_slate_source"
                                                [(ngModel)]="slateSourceIncluded"
                                                (change)="mutuallyExclusiveSources(true)"
                                            />
                                            <label class="form-check-label" for="is_slate_source"
                                                >{{ "SLATE_SOURCE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                            ></label>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-group-div">
                                    <zx-source-select
                                        name="slateSource"
                                        [(model)]="slateSourceId"
                                        [(ngModel)]="slateSourceId"
                                        [disabled]="!slateSourceIncluded"
                                        [ngClass]="{
                                            'is-invalid':
                                                slateSourceIncluded &&
                                                form.submitted &&
                                                (form.controls.transcodingSource?.errors || transcodingSource?.disable_autopull)
                                        }"
                                        [filter]="uniqueSourceFilter()"
                                    ></zx-source-select>
                                </div>
                            </div>
                        </div>
                        <!-- Log SCTE-35 -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="log_scte" name="log_scte" [(ngModel)]="channel.log_scte" />
                                <label class="form-check-label" for="log_scte">{{ "LOG_HLS_AD_MARKERS" | translate }}</label>
                            </div>
                        </div>

                        <!-- Preserve Closed Captions  -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="ccaps" name="ccaps" [(ngModel)]="channel.copy_closed_captions" />
                                <label class="form-check-label" for="ccaps">{{ "CLOSED_CAPTION_PRESERVE" | translate }}</label>
                            </div>
                        </div>

                        <!-- Crop -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="crop" name="crop" [(ngModel)]="crop" />
                                <label class="form-check-label" for="crop"
                                    >{{ "CROP" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>
                        <fieldset class="bordered crop-settings" *ngIf="crop === true">
                            <legend>{{ "CROP_SETTINGS" | translate }}</legend>
                            <!-- Units -->
                            <div class="form-group">
                                <label for="crop_mode" class="minw-50px">{{ "UNITS" | translate }}</label>
                                <mat-button-toggle-group name="crop_mode" aria-label="crop mode" [(ngModel)]="cropMode">
                                    <mat-button-toggle value="pixels">{{ "PIXELS" | translate | titlecase }}</mat-button-toggle>
                                    <mat-button-toggle value="percentages">{{ "PERCENTAGES" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <!-- Pixels -->
                            <fieldset *ngIf="cropMode === 'pixels'">
                                <legend class="sr-only">{{ "PIXEL_SETTINGS" | translate }}</legend>
                                <div class="row mb-3">
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label class="me-2" for="pixelCropLeft" [ngClass]="{ 'is-invalid': form.submitted && pxCropLeft.errors }">{{
                                                    "X_LEFT" | translate
                                                }}</label>
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="pixelCropLeft"
                                                    name="pixelCropLeft"
                                                    placeholder="{{ 'PIXELS' | translate }}"
                                                    pattern="^\d+$"
                                                    [(ngModel)]="pixelCropLeft"
                                                    min="0"
                                                    required
                                                    #pxCropLeft="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && pxCropLeft.errors }"
                                                />
                                                <div *ngIf="pxCropLeft.invalid" class="invalid-feedback">
                                                    <div *ngIf="pxCropLeft.errors.required">{{ "X_LEFT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="pxCropLeft.errors.pattern">
                                                        {{ "X_LEFT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pxCropLeft.errors }">{{
                                                    "PX" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label class="me-2" for="pixelCropTop" [ngClass]="{ 'is-invalid': form.submitted && pxCropTop.errors }">{{
                                                    "Y_TOP" | translate
                                                }}</label>
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="pixelCropTop"
                                                    name="pixelCropTop"
                                                    placeholder="{{ 'PIXELS' | translate }}"
                                                    pattern="^\d+$"
                                                    [(ngModel)]="pixelCropTop"
                                                    min="0"
                                                    required
                                                    #pxCropTop="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && pxCropTop.errors }"
                                                />
                                                <div *ngIf="pxCropTop.invalid" class="invalid-feedback">
                                                    <div *ngIf="pxCropTop.errors.required">{{ "Y_TOP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="pxCropTop.errors.pattern">
                                                        {{ "Y_TOP" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pxCropTop.errors }">{{
                                                    "PX" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label class="me-2" for="pixelCropWidth" [ngClass]="{ 'is-invalid': form.submitted && pxCropWidth.errors }">{{
                                                    "WIDTH" | translate
                                                }}</label>
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="pixelCropWidth"
                                                    name="pixelCropWidth"
                                                    placeholder="{{ 'PIXELS' | translate }}"
                                                    pattern="^\d+$"
                                                    [(ngModel)]="pixelCropWidth"
                                                    min="0"
                                                    required
                                                    #pxCropWidth="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && pxCropWidth.errors }"
                                                />
                                                <div *ngIf="pxCropWidth.invalid" class="invalid-feedback">
                                                    <div *ngIf="pxCropWidth.errors.required">{{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="pxCropWidth.errors.pattern">
                                                        {{ "WIDTH" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pxCropWidth.errors }">{{
                                                    "PX" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label class="me-2" for="pixelCropHeight" [ngClass]="{ 'is-invalid': form.submitted && pxCropHeight.errors }">{{
                                                    "HEIGHT" | translate
                                                }}</label>
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="pixelCropHeight"
                                                    name="pixelCropHeight"
                                                    placeholder="{{ 'PIXELS' | translate }}"
                                                    pattern="^\d+$"
                                                    [(ngModel)]="pixelCropHeight"
                                                    min="0"
                                                    required
                                                    #pxCropHeight="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && pxCropHeight.errors }"
                                                />
                                                <div *ngIf="pxCropHeight.invalid" class="invalid-feedback">
                                                    <div *ngIf="pxCropHeight.errors.required">{{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="pxCropHeight.errors.pattern">
                                                        {{ "HEIGHT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pxCropHeight.errors }">{{
                                                    "PX" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            <!-- Percentages -->
                            <fieldset *ngIf="cropMode === 'percentages'">
                                <legend class="sr-only">{{ "PERCENTAGE_SETTINGS" | translate }}</legend>
                                <div class="row mb-3">
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label
                                                    class="me-2"
                                                    for="percentageCropLeft"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropLeft.errors }"
                                                    >{{ "X_LEFT" | translate }}</label
                                                >
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="percentageCropLeft"
                                                    name="percentageCropLeft"
                                                    placeholder="{{ 'PERCENTAGE' | translate }}"
                                                    [(ngModel)]="percentageCropLeft"
                                                    [min]="0"
                                                    [max]="100"
                                                    required
                                                    #perCropLeft="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropLeft.errors }"
                                                />
                                                <div *ngIf="perCropLeft.invalid" class="invalid-feedback">
                                                    <div *ngIf="perCropLeft.errors.required">{{ "X_LEFT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="perCropLeft.errors.min || perCropLeft.errors.max">
                                                        {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && perCropLeft.errors }">{{
                                                    "%" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label class="me-2" for="percentageCropTop" [ngClass]="{ 'is-invalid': form.submitted && perCropTop.errors }">{{
                                                    "Y_TOP" | translate
                                                }}</label>
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="percentageCropTop"
                                                    name="percentageCropTop"
                                                    placeholder="{{ 'PERCENTAGE' | translate }}"
                                                    [(ngModel)]="percentageCropTop"
                                                    [min]="0"
                                                    [max]="100"
                                                    required
                                                    #perCropTop="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropTop.errors }"
                                                />
                                                <div *ngIf="perCropTop.invalid" class="invalid-feedback">
                                                    <div *ngIf="perCropTop.errors.required">{{ "Y_TOP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="perCropTop.errors.min || perCropTop.errors.max">
                                                        {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && perCropTop.errors }">{{
                                                    "%" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label
                                                    class="me-2"
                                                    for="percentageCropWidth"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropWidth.errors }"
                                                    >{{ "WIDTH" | translate }}</label
                                                >
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="percentageCropWidth"
                                                    name="percentageCropWidth"
                                                    placeholder="{{ 'PERCENTAGE' | translate }}"
                                                    [(ngModel)]="percentageCropWidth"
                                                    [min]="0"
                                                    [max]="100"
                                                    required
                                                    #perCropWidth="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropWidth.errors }"
                                                />
                                                <div *ngIf="perCropWidth.invalid" class="invalid-feedback">
                                                    <div *ngIf="perCropWidth.errors.required">{{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="perCropWidth.errors.min || perCropWidth.errors.max">
                                                        {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && perCropWidth.errors }">{{
                                                    "%" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="input-group align-items-center">
                                            <div class="input-group-prepend w-52px">
                                                <label
                                                    class="me-2"
                                                    for="percentageCropHeight"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropHeight.errors }"
                                                    >{{ "HEIGHT" | translate }}</label
                                                >
                                            </div>
                                            <div class="input-group-div">
                                                <input
                                                    type="number"
                                                    class="form-control"
                                                    id="percentageCropHeight"
                                                    name="percentageCropHeight"
                                                    placeholder="{{ 'PERCENTAGE' | translate }}"
                                                    [(ngModel)]="percentageCropHeight"
                                                    [min]="0"
                                                    [max]="100"
                                                    required
                                                    #perCropHeight="ngModel"
                                                    [ngClass]="{ 'is-invalid': form.submitted && perCropHeight.errors }"
                                                />
                                                <div *ngIf="perCropHeight.invalid" class="invalid-feedback">
                                                    <div *ngIf="perCropHeight.errors.required">{{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                    <div *ngIf="perCropHeight.errors.min || perCropHeight.errors.max">
                                                        {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-group-append">
                                                <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && perCropHeight.errors }">{{
                                                    "%" | translate
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </fieldset>

                        <!-- Transcoding Profiles -->
                        <div class="form-group">
                            <label for="transcoding_profiles" [ngClass]="{ 'is-invalid': form.submitted && selectedBitrates.length === 0 }"
                                >{{ "RENDITIONS" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                                ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                            <div class="table-responsive">
                                <table class="table table-sm bordered m-0" [ngClass]="{ 'is-invalid': form.submitted && selectedBitrates.length === 0 }">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="w-220px">{{ "TRANSCODING_PROFILE" | translate }}</th>
                                            <th scope="col" class="w-250px">{{ "VIDEO" | translate }}</th>
                                            <th scope="col" class="w-60px">{{ "VIDEO_CODEC" | translate }}</th>
                                            <th scope="col">{{ "AUDIO" | translate }}</th>
                                            <th scope="col" class="w-60px">{{ "AUDIO_CODEC" | translate }}</th>
                                            <th scope="col" class="w-110px">
                                                {{ "BITRATE" | translate }} [kbps]
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="BitrateContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #BitrateContent>
                                                    <p class="mb-0">{{ "TOOLTIP.CHANNEL_BITRATE" | translate }}</p>
                                                </ng-template>
                                            </th>
                                            <th scope="col" *ngIf="multiGpu" translate>GPU</th>
                                            <th scope="col" class="w-50px text-end">
                                                <fa-icon
                                                    class=""
                                                    icon="info-circle"
                                                    [ngbTooltip]="TPIContent"
                                                    triggers="hover click"
                                                    [closeDelay]="500"
                                                ></fa-icon>
                                                <ng-template #TPIContent>
                                                    <p class="mb-0">{{ "TOOLTIP.CHANNEL_TRANSCODING_PROFILES_INSTRUCTIONS" | translate }}</p>
                                                </ng-template>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="!profilesLoading && (sources?.length > 0 || selectedBitrates?.length > 0)">
                                        <tr *ngFor="let bitrate of selectedBitrates; index as index">
                                            <td title="{{ bitrate.profile.name }}" class="maxw-80px ellipsis">
                                                {{ bitrate.profile.name }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_video && !bitrate.profile.source_resolution
                                                        ? bitrate.profile.width + 'x' + bitrate.profile.height
                                                        : bitrate.profile.keep_video || bitrate.profile.source_resolution
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}{{
                                                    bitrate.profile.do_video && !bitrate.profile.keep_video
                                                        ? ' @ ' + (bitrate.profile.bitrate_avg | number : '1.0-0') + ' kbps'
                                                        : ''
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_video && !bitrate.profile.source_resolution
                                                        ? bitrate.profile.width + "x" + bitrate.profile.height
                                                        : bitrate.profile.keep_video || bitrate.profile.source_resolution
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                                {{
                                                    bitrate.profile.do_video && !bitrate.profile.keep_video
                                                        ? " @ " + (bitrate.profile.bitrate_avg | number : "1.0-0") + " kbps"
                                                        : ""
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_video
                                                        ? bitrate.profile.video_codec === 'h265'
                                                            ? ('H.265' | translate)
                                                            : ('H.264' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_video
                                                        ? bitrate.profile.video_codec === "h265"
                                                            ? ("H.265" | translate)
                                                            : ("H.264" | translate)
                                                        : "-"
                                                }}
                                            </td>

                                            <td
                                                title="{{
                                                    bitrate.profile.do_audio
                                                        ? (bitrate.profile.audio_bitrate | number : '1.0-0') + ' kbps'
                                                        : bitrate.profile.keep_audio
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_audio
                                                        ? (bitrate.profile.audio_bitrate | number : "1.0-0") + " kbps"
                                                        : bitrate.profile.keep_audio
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    bitrate.profile.do_audio
                                                        ? bitrate.profile.audio_codec === 'aac'
                                                            ? ('AAC' | translate)
                                                            : bitrate.profile.audio_codec === 'mpga1'
                                                            ? ('SHORT_MPGA' | translate)
                                                            : ('AC3' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    bitrate.profile.do_audio
                                                        ? bitrate.profile.audio_codec === "aac"
                                                            ? ("AAC" | translate)
                                                            : bitrate.profile.audio_codec === "mpga1"
                                                            ? ("SHORT_MPGA" | translate)
                                                            : ("AC3" | translate)
                                                        : "-"
                                                }}
                                            </td>
                                            <td>
                                                <input
                                                    type="number"
                                                    class="form-control form-control-sm form-control-xs"
                                                    id="bitrate_{{ index }}"
                                                    name="bitrate_{{ index }}"
                                                    [(ngModel)]="bitrate.kbps"
                                                    pattern="^\d*[1-9]\d*$"
                                                    placeholder="{{ 'BITRATE' | translate }}"
                                                    [ngClass]="{ 'is-invalid': form.submitted && form.controls['bitrate_' + index]?.errors }"
                                                    required
                                                />
                                                <div *ngIf="form.controls['bitrate_' + index]?.invalid" class="invalid-feedback">
                                                    <div *ngIf="form.controls['bitrate_' + index]?.errors.required">
                                                        {{ "BITRATE" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                    </div>
                                                    <div *ngIf="form.controls['bitrate_' + index]?.errors.pattern">{{ "INVALID_NUMBER" | translate }}.</div>
                                                </div>
                                            </td>
                                            <td *ngIf="multiGpu">
                                                <ng-select
                                                    name="gpu_id_{{ index }}"
                                                    class="form-control form-control-sm form-control-xs"
                                                    [searchable]="false"
                                                    [clearable]="false"
                                                    [(ngModel)]="bitrate.gpu_id"
                                                    [items]="[0, 1, 2, 3]"
                                                ></ng-select>
                                            </td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary"
                                                    (click)="deselectTranscodedBitrate(bitrate)"
                                                >
                                                    <fa-icon icon="minus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td [attr.colspan]="multiGpu ? 6 : 5">
                                                <div class="form-inline">
                                                    <div class="form-group stay-inline">
                                                        <label for="profilesFilter">{{ "FILTER" | translate }}:</label>
                                                        <input
                                                            type="text"
                                                            name="profilesFilter"
                                                            class="ms-1 form-control form-control-sm form-control-xs maxw-140px"
                                                            [(ngModel)]="profilesFilter"
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr
                                            *ngFor="
                                                let profile of profiles
                                                    | filter : ['profile'] : profilesFilter
                                                    | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
                                            "
                                            (click)="selectTranscodedBitrate(profile)"
                                            class="cursor-pointer row-hover"
                                        >
                                            <td class="maxw-80px ellipsis">
                                                <ngb-highlight title="{{ profile.name }}" [result]="profile.name" [term]="profilesFilter"></ngb-highlight>
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_video && !profile.source_resolution
                                                        ? profile.width + 'x' + profile.height
                                                        : profile.keep_video || profile.source_resolution
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}{{
                                                    profile.do_video && !profile.keep_video ? ' @ ' + (profile.bitrate_avg | number : '1.0-0') + ' kbps' : ''
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    profile.do_video && !profile.source_resolution
                                                        ? profile.width + "x" + profile.height
                                                        : profile.keep_video || profile.source_resolution
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                                {{ profile.do_video && !profile.keep_video ? " @ " + (profile.bitrate_avg | number : "1.0-0") + " kbps" : "" }}
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_video ? (profile.video_codec === 'h265' ? ('H.265' | translate) : ('H.264' | translate)) : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{ profile.do_video ? (profile.video_codec === "h265" ? ("H.265" | translate) : ("H.264" | translate)) : "-" }}
                                            </td>

                                            <td
                                                title="{{
                                                    profile.do_audio
                                                        ? (profile.audio_bitrate | number : '1.0-0') + ' kbps'
                                                        : profile.keep_audio
                                                        ? ('ORIGINAL' | translate)
                                                        : ('REMOVE' | translate)
                                                }}"
                                                class="maxw-80px ellipsis"
                                            >
                                                {{
                                                    profile.do_audio
                                                        ? (profile.audio_bitrate | number : "1.0-0") + " kbps"
                                                        : profile.keep_audio
                                                        ? ("ORIGINAL" | translate)
                                                        : ("REMOVE" | translate)
                                                }}
                                            </td>
                                            <td
                                                title="{{
                                                    profile.do_audio
                                                        ? profile.audio_codec === 'aac'
                                                            ? ('AAC' | translate)
                                                            : profile.audio_codec === 'mpga1'
                                                            ? ('SHORT_MPGA' | translate)
                                                            : ('AC3' | translate)
                                                        : '-'
                                                }}"
                                                class="ellipsis"
                                            >
                                                {{
                                                    profile.do_audio
                                                        ? profile.audio_codec === "aac"
                                                            ? ("AAC" | translate)
                                                            : profile.audio_codec === "mpga1"
                                                            ? ("SHORT_MPGA" | translate)
                                                            : ("AC3" | translate)
                                                        : "-"
                                                }}
                                            </td>
                                            <td>{{ profileKbps(profile) }} {{ profileKbps(profile) ? " kbps" : "" }}</td>
                                            <td *ngIf="multiGpu"></td>
                                            <td class="text-end">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-xs btn-round-xs btn-outline-primary"
                                                    (click)="selectTranscodedBitrate(profile)"
                                                >
                                                    <fa-icon icon="plus" size="sm"></fa-icon>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="profilesLoading">
                                        <tr>
                                            <td colspan="5" class="text-center">{{ "LOADING" | translate }}...</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="!profilesLoading && (profiles | filter : ['profile'] : profilesFilter).length === 0">
                                        <tr>
                                            <td colspan="5" class="text-center">{{ "NO_TRANSCODING_PROFILES" | translate }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="form.submitted && selectedBitrates.length === 0" class="invalid-feedback">
                                {{ "AT_LEAST_1_PROFILE_IS_REQUIRED" | translate }}.
                            </div>
                            <div class="mt-2" *ngIf="profiles && (profiles | filter : ['profile'] : profilesFilter).length > pageSize">
                                <ngb-pagination
                                    class="mb-0"
                                    [collectionSize]="(profiles | filter : ['profile'] : profilesFilter).length"
                                    [(page)]="page"
                                    [pageSize]="pageSize"
                                    [maxSize]="2"
                                ></ngb-pagination>
                            </div>
                        </div>

                        <!-- Multi GPU -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="multigpu"
                                    name="multigpu"
                                    [(ngModel)]="multiGpu"
                                    [disabled]="channel.transcode_encoder !== 'any'"
                                />
                                <label class="form-check-label" for="multigpu"
                                    >{{ "MULTI_GPU" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                                <br />
                                <small>{{ "CHOOSING_ENCODER_WARNING" | translate }}</small>
                            </div>
                        </div>

                        <!-- ignoreTranscodeThresholds -->
                        <div class="form-group">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input"
                                    id="ignoreTranscodeThresholds"
                                    name="ignoreTranscodeThresholds"
                                    [(ngModel)]="channel.ignore_transcode_thresholds"
                                />
                                <label class="form-check-label" for="ignoreTranscodeThresholds"
                                    >{{ "IGNORE_TRANSCODE_THRESHOLDS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                ></label>
                            </div>
                        </div>

                        <!-- State -->
                        <section id="state" *ngIf="!isEdit">
                            <div class="form-section-title">
                                <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                    {{ "INITIAL_STATE" | translate }}
                                </h3>
                            </div>
                            <!-- Enabled -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                    <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                                </div>
                            </div>
                            <!-- Muted -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="channel.muted" />
                                    <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                                </div>
                            </div>
                        </section>

                        <!-- Advanced -->
                        <div class="toggle-fieldset-title" [ngClass]="{ closed: !showAdvanced }">
                            <h3
                                class="toggle-fieldset"
                                (click)="showAdvanced = !showAdvanced"
                                [ngClass]="[
                                    !showAdvanced ? 'closed' : '',
                                    form.submitted &&
                                    (form.controls.hls_chunks?.errors ||
                                        form.controls.hls_chunk_duration?.errors ||
                                        form.controls.dash_chunks?.errors ||
                                        form.controls.dash_chunk_duration?.errors)
                                        ? 'is-invalid'
                                        : ''
                                ]"
                            >
                                {{ "ADVANCED" | translate }}<fa-icon icon="plus" size="xs" [fixedWidth]="true" *ngIf="!showAdvanced"></fa-icon
                                ><fa-icon icon="minus" size="xs" [fixedWidth]="true" *ngIf="showAdvanced"></fa-icon>
                            </h3>
                        </div>

                        <fieldset class="mb-3 bordered no-bg" [ngClass]="{ 'd-none': !showAdvanced }">
                            <!-- Billing Code -->
                            <div>
                                <div class="form-group">
                                    <label for="billing_code" [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }">
                                        {{ "BILLING_CODE" | translate }}
                                        <fa-icon
                                            *ngIf="isEdit"
                                            icon="redo"
                                            size="sm"
                                            [ngbTooltip]="RestartContent"
                                            triggers="hover click"
                                            [closeDelay]="500"
                                        ></fa-icon
                                    ></label>
                                    <input
                                        type="text"
                                        autocomplete="cc-csc"
                                        id="billing_code"
                                        name="billing_code"
                                        placeholder="{{ 'BILLING_CODE' | translate }}"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="channel.billing_code"
                                        [ngClass]="{ 'is-invalid': form.submitted && billing_code.errors }"
                                        #billing_code="ngModel"
                                        (change)="this.onChange()"
                                    />
                                </div>
                                <!-- Billing password -->
                                <div class="form-group">
                                    <label for="billing_password" [ngClass]="{ 'is-invalid': billing_password.errors }"
                                        >{{ "BILLING_PASSWORD" | translate
                                        }}<fa-icon
                                            *ngIf="isEdit"
                                            icon="redo"
                                            size="sm"
                                            [ngbTooltip]="RestartContent"
                                            triggers="hover click"
                                            [closeDelay]="500"
                                        ></fa-icon
                                    ></label>
                                    <input
                                        type="password"
                                        autocomplete="cc-csc"
                                        id="billing_password"
                                        name="billing_password"
                                        placeholder="{{ 'BILLING_PASSWORD' | translate }}"
                                        class="form-control form-control-sm"
                                        [(ngModel)]="channel.billing_password"
                                        [ngClass]="{ 'is-invalid': billing_password.errors }"
                                        #billing_password="ngModel"
                                        (change)="this.onChange()"
                                    />
                                    <div *ngIf="billing_password.invalid" class="invalid-feedback">
                                        <div *ngIf="billing_password.errors.validationFailed">
                                            {{ "BILLING_CODE" | translate }} {{ "VALIDATION_FAILED_CHECK_CONNECTION" | translate }}.
                                        </div>
                                        <div *ngIf="billing_password.errors.invalidCode">{{ "INVALID_BILLING_CODE" | translate }}.</div>
                                    </div>
                                    <zx-billing-codes-check [codeControl]="billing_code" [passControl]="billing_password"></zx-billing-codes-check>
                                </div>
                            </div>
                            <fieldset class="form-group bordered">
                                <div class="form-group mb-1">
                                    <!-- HLS -->
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="do_hls"
                                            name="do_hls"
                                            [(ngModel)]="channel.disable_hls"
                                            [ngClass]="{ 'is-invalid': form.submitted && channel.disable_cmaf && channel.disable_hls }"
                                            invertChecked
                                        />
                                        <label class="form-check-label semibold" for="do_hls"
                                            >{{ "HLS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                        ></label>
                                    </div>
                                    <!-- DASH -->
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="do_cmaf"
                                            name="do_cmaf"
                                            [(ngModel)]="channel.disable_cmaf"
                                            [ngClass]="{ 'is-invalid': form.submitted && channel.disable_cmaf && channel.disable_hls }"
                                            invertChecked
                                        />
                                        <label class="form-check-label" for="do_cmaf"
                                            >{{ "CMAF_DASH_fMP4" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                        ></label>
                                    </div>
                                </div>

                                <div class="form-group mb-0">
                                    <div class="row">
                                        <div class="col">
                                            <label for="hls_chunks" [ngClass]="{ 'is-invalid': form.submitted && hls_chunks.errors }">{{
                                                "CHUNKS" | translate
                                            }}</label>
                                            <input
                                                type="number"
                                                id="hls_chunks"
                                                name="hls_chunks"
                                                class="form-control form-control-sm"
                                                [(ngModel)]="channel.hls_chunks"
                                                [min]="1"
                                                [max]="50"
                                                #hls_chunks="ngModel"
                                                [ngClass]="{ 'is-invalid': form.submitted && hls_chunks.errors }"
                                            />
                                            <div *ngIf="hls_chunks.invalid" class="invalid-feedback">
                                                <div *ngIf="hls_chunks.errors.min || hls_chunks.errors.max">
                                                    {{ "HLS_CHUNKS" | translate }} {{ "MUST_BE_BETWEEN_1_AND_50" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label for="hls_chunk_duration" [ngClass]="{ 'is-invalid': form.submitted && hls_chunk_duration.errors }"
                                                >{{ "DURATION" | translate }} [{{ "SECONDS" | translate | lowercase }}]</label
                                            >
                                            <input
                                                type="number"
                                                id="hls_chunk_duration"
                                                name="hls_chunk_duration"
                                                class="form-control form-control-sm"
                                                [(ngModel)]="channel.hls_chunk_duration"
                                                [min]="1"
                                                [max]="20"
                                                #hls_chunk_duration="ngModel"
                                                [ngClass]="{ 'is-invalid': form.submitted && hls_chunk_duration.errors }"
                                            />
                                            <div *ngIf="hls_chunk_duration.invalid" class="invalid-feedback">
                                                <div *ngIf="hls_chunk_duration.errors.min || hls_chunk_duration.errors.max">
                                                    {{ "HLS_CHUNK_DURATION" | translate }} {{ "MUST_BE_BETWEEN_1_AND_20" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <small>
                                        {{ "REQUIRES_VERSION_12_BROADCASTER_LEAVE_BLANK_TO_USE_BROADCASTER_CONFIGURATION" | translate }}
                                    </small>
                                </div>
                            </fieldset>

                            <!---->
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="webvtt_cc" name="webvtt_cc" [(ngModel)]="channel.webvtt_cc" />
                                    <label class="form-check-label" for="webvtt_cc"
                                        >{{ "CONVERT_CEA708_TO_WEBVTT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Timecode configuration -->
                            <fieldset class="form-group">
                                <legend for="timecode_mode">
                                    {{ "TIMECODE_CONFIGURATION" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                </legend>
                                <mat-button-toggle-group name="timecode_mode" aria-label="timecode mode" [(ngModel)]="channel.timecode_mode">
                                    <mat-button-toggle value="none">{{ "NONE" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="system">{{ "SYSTEM_TIME" | translate }}</mat-button-toggle>
                                    <mat-button-toggle value="sei">{{ "SEI_TIMECODES" | translate }}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </fieldset>
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="disable_synchronization"
                                        name="disable_synchronization"
                                        [(ngModel)]="channel.disable_synchronization"
                                    />
                                    <label class="form-check-label" for="disable_synchronization"
                                        >{{ "DISABLE_RENDITION_SYNCHRONIZATION" | translate
                                        }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Drop unaligned segments -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="strict_chunks"
                                        name="strict_chunks"
                                        [(ngModel)]="channel.strict_chunks"
                                    />
                                    <label class="form-check-label" for="strict_chunks"
                                        >{{ "ADAPTIVE_STRICT_SEGMENTS" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="force_i_frame"
                                        name="force_i_frame"
                                        [(ngModel)]="channel.force_i_frame_on_scte"
                                    />
                                    <label class="form-check-label" for="force_i_frame"
                                        >{{ "FORCE_I_FRAME_ON_SCTE" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                    </label>
                                </div>
                            </div>

                            <!-- Segment on SCTE-35 -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="segment_on_scte35"
                                        name="segment_on_scte35"
                                        [(ngModel)]="channel.segment_on_scte35"
                                    />
                                    <label class="form-check-label" for="segment_on_scte35"
                                        >{{ "SEGMENT_ON_SCTE35" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Don't add EXT-X-CUE-IN -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="skip_cue_in" name="skip_cue_in" [(ngModel)]="channel.skip_cue_in" />
                                    <label class="form-check-label" for="skip_cue_in"
                                        >{{ "SKIP_CUE_IN" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Drop first segment -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="drop_first_segment"
                                        name="drop_first_segment"
                                        [(ngModel)]="channel.drop_first_segment"
                                    />
                                    <label class="form-check-label" for="drop_first_segment"
                                        >{{ "DROP_FIRST_SEGMENT" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                    ></label>
                                </div>
                            </div>
                            <!-- Support MultiAudio -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="support_multiaudio"
                                        name="support_multiaudio"
                                        [(ngModel)]="channel.support_multiaudio"
                                    />
                                    <label class="form-check-label" for="support_multiaudio"
                                        >{{ "SUPPORT_MULTIAUDIO" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                    </label>
                                </div>
                            </div>
                            <!-- Ascending Playlist Order -->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="order_ascending"
                                        name="order_ascending"
                                        [(ngModel)]="channel.order_ascending"
                                    />
                                    <label class="form-check-label" for="order_ascending"
                                        >{{ "ASCENDING_PLAYLIST_ORDER" | translate }} <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                                    </label>
                                </div>
                            </div>
                            <!-- Encoder -->
                            <div class="form-group">
                                <label for="transcodeEncoder"
                                    >{{ "ENCODER" | translate
                                    }}<fa-icon
                                        *ngIf="isEdit"
                                        icon="redo"
                                        size="sm"
                                        [ngbTooltip]="RestartContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                ></label>
                                <ng-select
                                    id="transcodeEncoder"
                                    name="transcodeEncoder"
                                    [items]="transcodeEncoderOptions"
                                    [clearable]="false"
                                    bindValue="id"
                                    bindLabel="name"
                                    placeholder="{{ 'SELECT_ENCODER' | translate }}"
                                    [(ngModel)]="channel.transcode_encoder"
                                    class="form-control"
                                    #transcodeEncoder="ngModel"
                                    (change)="encoderChanged($event)"
                                >
                                </ng-select>
                            </div>

                            <!-- Transcoding Threads -->
                            <div class="form-group" *ngIf="channel.transcode_encoder === 'x264'">
                                <label for="transcodeThreads"
                                    >{{ "THREADS" | translate
                                    }}<fa-icon
                                        *ngIf="isEdit"
                                        icon="redo"
                                        size="sm"
                                        [ngbTooltip]="RestartContent"
                                        triggers="hover click"
                                        [closeDelay]="500"
                                    ></fa-icon
                                ></label>
                                <input
                                    type="number"
                                    [min]="0"
                                    [max]="16"
                                    id="transcode_threads"
                                    name="transcode_threads"
                                    placeholder=" {{ 'THREADS' | translate }} "
                                    class="form-control form-control-sm"
                                    [ngClass]="{ 'is-invalid': form.submitted && transcode_threads.errors }"
                                    [(ngModel)]="channel.transcode_threads"
                                    #transcode_threads="ngModel"
                                />
                                <div *ngIf="transcode_threads.invalid" class="invalid-feedback">
                                    {{ "THREADS" | translate }} {{ "MUST_BE_BETWEEN_0_AND_16" | translate }}.
                                </div>
                            </div>
                        </fieldset>
                    </section>

                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>

                    <hr class="mt-0" />

                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" *ngIf="!isEdit && !isClone" (click)="back()" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
