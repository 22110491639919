import { Component, OnInit, OnDestroy, ViewChild, ComponentRef, inject, ElementRef } from "@angular/core";
import { Router, ActivationEnd, RouterOutlet } from "@angular/router";
import { Subscription, interval, BehaviorSubject, firstValueFrom } from "rxjs";
import { filter } from "rxjs/operators";

import { StatusTextPipe } from "../../../pipes/status-text.pipe";
import { Constants } from "../../../constants/constants";
import { ModalService } from "../../../components/shared/modals/modal.service";
import { UsersService } from "../../account-management/users/users.service";
import { UserPermissions, KeyMap, RecoveryState } from "../../../models/shared";

import { ChannelsService } from "../channels.service";
import { ChannelComponent } from "../channel/channel.component";
import {
    ChannelTypes,
    isMediaConnect,
    isMediaLive,
    isAdaptiveChannel,
    isDeliveryChannel,
    isFailoverChannel,
    FailoverChannel
} from "../channel";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import { ZxStatusFullComponent } from "../../../components/shared/zx-status-full/zx-status-full.component";
import { ZxClusterComponent } from "src/app/components/shared/zx-cluster/zx-cluster.component";
import { ZxBroadcasterComponent } from "src/app/components/shared/zx-broadcaster/zx-broadcaster.component";
import { TableSchema } from "../../../components/shared/table-list/table-list.component";
import { ZxChannelsSourceComponent } from "src/app/components/shared/table-list/tables-components/zx-channels-source/zx-channels-source.component";
import { ZxChannelsTargetColumnComponent } from "src/app/components/shared/zx-channels-target-column/zx-channels-target-column.component";
import { ZxTagsListComponent } from "src/app/components/shared/zx-tags-list/zx-tags-list.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { ZxChannelTypeComponent } from "src/app/components/shared/zx-channel-type/zx-channel-type.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ColumnFilterType } from "src/app/components/shared/filter/filter.component";
import {
    IconColumnComponent,
    IconTypes
} from "src/app/components/shared/table-list/tables-components/icon-column/icon-column.component";
import { TagsService } from "../../configuration/tags/tags.service";
import { SharedService } from "src/app/services/shared.service";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { DisasterRecoveryDialogComponent } from "src/app/components/shared/modals/disaster-recovery-dialog/disaster-recovery-dialog.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-channel-list",
    templateUrl: "./channel-list.component.html"
})
export class ChannelListComponent implements OnInit, OnDestroy {
    refreshing = false;
    channels: ChannelTypes[];
    channelId: number;
    channelName: string;
    channelType: string;
    selectedChannel: ChannelTypes;
    selectedRows: ChannelTypes[] = [];
    private rawChannelsBS$ = new BehaviorSubject<ChannelTypes[]>([]);
    userPermissions: UserPermissions;
    urls = Constants.urls;

    firstLoad = true;
    currentPageAdaptiveIDs: number[] = [];
    currentPageDeliveryIDs: number[] = [];
    currentPageFlowIDs: number[] = [];
    currentPageMediaLiveIDs: number[] = [];
    currentPageFailoverIDs: number[] = [];
    currentSortDirection: string;

    isResizing: boolean;
    @ViewChild(RouterOutlet) channel: RouterOutlet;
    @ViewChild("leftContainer", { static: true }) leftContainer: ElementRef;

    private routeSubscription: Subscription;
    private channelsSubscription: Subscription;
    private channelsRefreshSubscription: Subscription;
    private splitterPositionSubscription: Subscription;

    splitterPosition = null;

    fromDate: string;
    toDate: string;
    isInsightRoute: boolean;
    multiSelectDisasterRecovery = false;
    private statusTextPipe = inject(StatusTextPipe);
    private router = inject(Router);
    private cs = inject(ChannelsService);
    private modalService = inject(ModalService);
    private userService = inject(UsersService);
    private mixpanelService = inject(MixpanelService);
    private translate = inject(TranslateService);
    private titleService = inject(TitleService);
    private tagsService = inject(TagsService);
    private sharedService = inject(SharedService);
    private broadcasterService = inject(BroadcastersService);
    private ngbModal = inject(NgbModal);
    tableColumnsSchema: TableSchema<KeyMap<ChannelTypes>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ChannelTypes>>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name,
            valueToExport: row => row.name,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({ showOtherIcons: true }),
            textValue: row => this.translate.instant(this.statusTextPipe.transform(row)),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            valueToExport: row => this.translate.instant(this.statusTextPipe.transform(row)),
            columnFilterType: ColumnFilterType.SELECT,
            columnFilterValue: row => this.translate.instant(this.statusTextPipe.simpleTransform(row)),
            columnSelectOptions: ["Ok", "Warning", "Error", "Other"]
        },
        {
            header: this.translate.instant("ERROR"),
            columnDef: "error",
            hideFromColumnChooser: true,
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                row => row.activeStates?.map(as => as.short_message)?.join(","),
                row => !!row.activeStates?.map(as => as.short_message)?.join(",")
            ),
            valueToExport: row => row.activeStates?.map(as => as.short_message)?.join(",")
        },
        {
            header: this.translate.instant("EVENT"),
            columnDef: "message",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => row.activeStates?.map(as => as.message)?.join(","),
                row => row.activeStates?.map(as => as.message)?.join(","),
                row => !!row.activeStates?.map(as => as.message)?.join(",")
            ),
            valueToExport: row => row.activeStates?.map(as => as.message)?.join(","),
            textValue: row => row.activeStates?.map(as => as.message)?.join(","),
            sortBy: row => row.activeStates?.map(as => as.message)?.join(","),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("EXTERNAL_ID"),
            columnDef: "external_id",
            width: 100,
            visible: false,
            component: ZxNgbHighlightComponent,
            // Add a type here to avoid a type error, even if external_id is empty its ok
            assignComponentsInputs: assignNgbHighlightInputsFactory<FailoverChannel>(
                row => row.external_id,
                row => row.external_id,
                row => !!row.external_id
            ),
            valueToExport: row => (row as FailoverChannel).external_id,
            textValue: row => (row as FailoverChannel).external_id,
            sortBy: row => (row as FailoverChannel).external_id,
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("PROC_CLUSTER"),
            columnDef: "processing_cluster",
            width: 200,
            visible: true,
            component: ZxClusterComponent,
            assignComponentsInputs: (
                clusterComponentRef: ComponentRef<ZxClusterComponent>,
                row: KeyMap<ChannelTypes>,
                searchTerm: string[]
            ) => {
                const clusterCompRef = clusterComponentRef.instance;
                const props = {
                    model: row?._frontData?.active_broadcaster,
                    showOtherIcons: false,
                    showStatusIcon: false,
                    showStatusText: false,
                    showLink: true,
                    showTag: false,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    clusterCompRef[key] = value;
                }

                if (!isMediaConnect(row) && !isMediaLive(row)) {
                    clusterCompRef.model = row.processingCluster;
                } else {
                    clusterCompRef.region = row.region;
                }
            },
            textValue: row => {
                if (!isMediaConnect(row) && !isMediaLive(row)) return row.processingCluster?.name;
                return row._frontData?.active_broadcaster?.name ?? row.region;
            },
            sortBy: row => {
                if (!isMediaConnect(row) && !isMediaLive(row)) return row.processingCluster?.name;
                return row._frontData?.active_broadcaster?.name ?? row.region;
            },
            valueToExport: row => {
                if ((row.adaptive || row.delivery) && row.processingCluster && row.processingCluster.name != null)
                    return row.processingCluster.name;
                if ((row.mediaconnect || row.medialive) && row.region) return row.region;
                return "";
            },
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("BROADCASTER"),
            columnDef: "active_broadcaster",
            width: 160,
            visible: true,
            component: ZxBroadcasterComponent,
            assignComponentsInputs: (
                broadcasterComponentRef: ComponentRef<ZxBroadcasterComponent>,
                row: KeyMap<ChannelTypes>,
                searchTerm: string[]
            ) => {
                const broadcasterCompRef = broadcasterComponentRef.instance;
                const props = {
                    model: row?._frontData?.active_broadcaster,
                    clusterId: row?.broadcaster_cluster_id,
                    showTag: false,
                    showLink: true,
                    showCluster: false,
                    showStatusIcon: false,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    broadcasterCompRef[key] = value;
                }
                if (!isMediaConnect(row)) {
                    broadcasterCompRef.clusterId = row.broadcaster_cluster_id;
                }
            },
            textValue: row => row?._frontData?.active_broadcaster?.name ?? "-",
            sortBy: row => row?._frontData?.active_broadcaster?.name,
            valueToExport: row =>
                row._frontData?.active_broadcaster && row._frontData?.active_broadcaster.name
                    ? row._frontData?.active_broadcaster.name
                    : "",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("SOURCES"),
            columnDef: "source",
            width: 240,
            visible: true,
            component: ZxChannelsSourceComponent,
            assignComponentsInputs: (
                sourceComponentRef: ComponentRef<ZxChannelsSourceComponent>,
                row: KeyMap<ChannelTypes>,
                searchTerm: string[]
            ) => {
                const statusComponentInstance = sourceComponentRef.instance;
                statusComponentInstance.channel = row;
                statusComponentInstance.searchTerm = searchTerm?.toString();
            },
            textValue: row => {
                if (isAdaptiveChannel(row)) {
                    return row.bitrates?.map(b => b.source?.stream_id).join("");
                } else if (isDeliveryChannel(row)) {
                    return row.sources?.map(s => s.source?.stream_id).join("");
                } else if (isMediaConnect(row)) {
                    return row.source?.name;
                } else if (isMediaLive(row)) {
                    return row.flow?.name ?? row.source?.name;
                } else {
                    return row.failoverSource?.name ?? "";
                }
            },
            sortBy: row => row._frontData?.sortableSources,
            valueToExport: row => {
                let sources = "";
                if (row.delivery && row.sources && row.sources.length) {
                    const sourceDescriptions: string[] = [];
                    for (const src of row.sources) {
                        const srcDesc: string[] = [src.source.name];
                        if (src.source.inputCluster.name) srcDesc.push(`@${src.source.inputCluster.name}`);
                        sourceDescriptions.push(srcDesc.join(""));
                    }
                    sources += sourceDescriptions.join(", ");
                }
                if (row.mediaconnect && row.source && row.source.name)
                    sources +=
                        row.source.name +
                        (row.source.mediaconnectFlow && row.source.mediaconnectFlow.name
                            ? " @ " + row.source.mediaconnectFlow.name
                            : "");
                if (row.medialive && row.source && row.source.name) sources += row.source.name;
                if (row.medialive && row.flow && row.flow.name) sources += row.flow.name;
                return sources;
            }
        },
        {
            header: this.translate.instant("TYPE"),
            columnDef: "type",
            width: 160,
            visible: true,
            component: ZxChannelTypeComponent,
            assignComponentsInputs: (
                ZxChannelTypeRef: ComponentRef<ZxChannelTypeComponent>,
                row: KeyMap<ChannelTypes>,
                searchTerm: string[]
            ) => {
                ZxChannelTypeRef.instance.channelType = this.cs.processChannelType(row);
                ZxChannelTypeRef.instance.searchTerm = searchTerm;
            },
            textValue: row => this.cs.processChannelType(row),
            sortBy: row => this.cs.processChannelType(row),
            valueToExport: row => this.cs.processChannelType(row),
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("TARGETS"),
            columnDef: "targets_summary",
            width: 240,
            visible: true,
            component: ZxChannelsTargetColumnComponent,
            assignComponentsInputs: (
                targetComponentRef: ComponentRef<ZxChannelsTargetColumnComponent>,
                row: KeyMap<ChannelTypes>
            ) => {
                const targetCompRef = targetComponentRef.instance;
                const props = {
                    channel: row
                };
                for (const key in props) {
                    const value = props[key];
                    targetCompRef[key] = value;
                }
            },
            textValue: row => {
                if (row.targetsSummary?.good > 0) {
                    return row.targetsSummary.good.toString();
                } else if (row.targetsSummary?.bad > 0) {
                    return row.targetsSummary.bad.toString();
                } else if (row.targetsSummary?.warning > 0) {
                    return row.targetsSummary.warning.toString();
                } else if (row.targetsSummary?.muted_bad > 0) {
                    return row.targetsSummary.muted_bad.toString();
                } else if (row.targetsSummary?.disabled > 0) {
                    return row.targetsSummary.disabled.toString();
                } else {
                    return row.targetsSummary?.pending > 0 ? row.targetsSummary.pending.toString() : "-";
                }
            },
            sortBy: row => row._frontData?.sortableTargets,
            valueToExport: row => {
                let targets = "";
                if (row.targetsSummary?.good) targets += "Ok(" + row.targetsSummary.good + ") ";
                if (row.targetsSummary?.bad) targets += "Error(" + row.targetsSummary.bad + ") ";
                if (row.targetsSummary?.warning) targets += "Warning(" + row.targetsSummary.warning + ") ";
                if (row.targetsSummary?.muted_bad) targets += "Muted(" + row.targetsSummary.muted_bad + ") ";
                if (row.targetsSummary?.disabled) targets += "Disabled(" + row.targetsSummary.disabled + ") ";
                if (row.targetsSummary?.pending) targets += "Disabled(" + row.targetsSummary.pending + ") ";
                return targets;
            }
        },
        {
            header: this.translate.instant("ALT_PATH"),
            columnDef: "alternative",
            width: 120,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<ChannelTypes>>(
                row => (this.cs.getDisasterRecoveryState(row) === RecoveryState.alternative ? "Active" : "Inactive"),
                row => (this.cs.getDisasterRecoveryState(row) === RecoveryState.alternative ? "Active" : "Inactive"),
                () => true
            ),
            sortBy: (row: KeyMap<ChannelTypes>) =>
                this.cs.getDisasterRecoveryState(row) === RecoveryState.alternative ? "Active" : "Inactive",
            textValue: (row: KeyMap<ChannelTypes>) =>
                this.cs.getDisasterRecoveryState(row) === RecoveryState.alternative ? "Active" : "Inactive",
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Active", "Inactive"],
            columnFilterValue: (row: KeyMap<ChannelTypes>) =>
                this.cs.getDisasterRecoveryState(row) === RecoveryState.alternative ? "Active" : "Inactive"
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 160,
            visible: false,
            component: ZxTagsListComponent,
            assignComponentsInputs: (
                sourceComponentRef: ComponentRef<ZxTagsListComponent>,
                row: KeyMap<ChannelTypes>,
                searchTerm: string[]
            ) => {
                const sourceCompRef = sourceComponentRef.instance;
                const props = {
                    model: row,
                    searchTerm: searchTerm
                };
                for (const key in props) {
                    const value = props[key];
                    sourceCompRef[key] = value;
                }
            },
            textValue: row => row.resourceTags?.map(t => t.name).join(", ") ?? "",
            sortBy: row => row.resourceTags?.map(t => t.name).join("") ?? "",
            valueToExport: row => row.resourceTags?.map(c => c.name).join(", ") ?? "",
            columnFilterType: ColumnFilterType.STRING
        },
        {
            header: this.translate.instant("PRIORITY"),
            columnDef: "priority",
            width: 80,
            visible: false,
            component: IconColumnComponent,
            assignComponentsInputs: (componentRef: ComponentRef<IconColumnComponent>, row: KeyMap<ChannelTypes>) => {
                const componentInstance = componentRef.instance;
                componentInstance.iconType = IconTypes.CHECK;
                componentInstance.showIcon = this.tagsService.isObjectVip(row.resourceTags);
            },
            sortBy: (row: ChannelTypes) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
            textValue: (row: ChannelTypes) => (this.tagsService.isObjectVip(row.resourceTags) ? "priority" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (this.tagsService.isObjectVip(row.resourceTags) ? "Yes" : "No")
        },
        {
            header: this.translate.instant("MUTED"),
            columnDef: "muted",
            width: 0,
            visible: false,
            textValue: row => (row.active_mute ? "muted" : ""),
            sortBy: row => (row.active_mute ? "muted" : ""),
            columnFilterType: ColumnFilterType.SELECT,
            columnSelectOptions: ["Yes", "No"],
            columnFilterValue: row => (row.active_mute ? "Yes" : "No")
        }
    ];

    constructor() {
        // Set Title
        this.titleService.setTitle("CHANNELS", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (
                    event.snapshot.params &&
                    event.snapshot.params.name &&
                    event.snapshot.params.channelId &&
                    event.snapshot.params.type
                ) {
                    this.channelName = event.snapshot.params.name;
                    this.channelId = urlBuilder.decode(event.snapshot.params.channelId);
                    this.channelType = event.snapshot.params.type;
                    this.isInsightRoute = false;
                } else if (event.snapshot.params && event.snapshot.params.InsightType) {
                    this.isInsightRoute = true;
                } else {
                    this.isInsightRoute = false;
                    this.channelId = null;
                    this.channelName = null;
                    this.channelType = null;
                }

                if (this.channelId) this.updateSelectedChannel();
                else this.selectedRows = [];
            });
    }

    async ngOnInit() {
        this.userPermissions = await firstValueFrom(this.userService.userPermissions);

        // local storage
        if (localStorage.getItem("splitter-width"))
            this.leftContainer.nativeElement.style.flexBasis = localStorage.getItem("splitter-width");

        if (localStorage.getItem("splitter-position"))
            this.sharedService.setSplitterPosition(parseInt(localStorage.getItem("splitter-position"), 10));

        // subs
        this.splitterPositionSubscription = this.sharedService.getSplitterPosition.subscribe(p => {
            this.splitterPosition = p;
        });

        this.channelsSubscription = this.cs.channels.subscribe(channels => {
            this.channels = channels;

            if (this.channels) {
                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;
                    this.prepTableData();
                    this.cs.refreshAdaptiveChannels(this.currentPageAdaptiveIDs, true);
                    this.cs.refreshDeliveryChannels(this.currentPageDeliveryIDs, true);
                    this.cs.refreshMediaConnectFlows(this.currentPageFlowIDs, true);
                    this.cs.refreshMediaLiveChannels(this.currentPageMediaLiveIDs, true);
                    this.cs.refreshFailoverChannels(this.currentPageFailoverIDs, true);
                }
                this.prepTableData();
            }
        });

        // Start Auto Refresh
        this.startChannelsRefresh();
        setTimeout(() => {
            if (this.channelName) this.updateSelectedChannel();
            else this.selectedRows = [];
        });
    }

    private updateSelectedChannel() {
        this.selectedChannel = this.channels?.find((c: ChannelTypes) => {
            if (
                [Constants.urls.channelTypes.adaptive, Constants.urls.channelTypes.transcoded].includes(
                    this.channelType
                )
            )
                return c.adaptive && c.id === this.channelId;
            if ([Constants.urls.channelTypes.delivery].includes(this.channelType))
                return c.delivery && c.id === this.channelId;
            if ([Constants.urls.channelTypes.mediaconnect].includes(this.channelType))
                return c.mediaconnect && c.id === this.channelId;
            if ([Constants.urls.channelTypes.medialive].includes(this.channelType))
                return c.medialive && c.id === this.channelId;
            if ([Constants.urls.channelTypes.failover].includes(this.channelType))
                return c.failover && c.id === this.channelId;
        });

        if (this.selectedChannel) this.selectedRows = [this.selectedChannel];
    }

    get allChannelObservable() {
        return this.rawChannelsBS$;
    }

    getServerBroadcasterId(channel: ChannelTypes) {
        let broadcasterId = 0;
        if (isAdaptiveChannel(channel)) {
            broadcasterId = channel.activeBroadcasterObjects?.bx_id ?? channel.broadcaster_id;
        } else if (isDeliveryChannel(channel)) {
            broadcasterId = channel.activeBroadcasterObjects[0]?.bx_id ?? channel.target_broadcaster_id;
        } else if (isFailoverChannel(channel)) {
            broadcasterId = channel.activeBroadcasterObjects?.bx_id ?? channel.deliveryChannel?.target_broadcaster_id;
        }
        return broadcasterId > 0 ? broadcasterId : null;
    }

    getAllChannelsBroadcasters() {
        const broadcasterIds = new Set<number>();
        this.channels.forEach(async channel => {
            const broadcasterId = this.getServerBroadcasterId(channel);
            if (!!broadcasterId) {
                broadcasterIds.add(broadcasterId);
            }
        });

        broadcasterIds.forEach(async broadcasterId => {
            const broadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
            if (!broadcaster) await firstValueFrom(this.broadcasterService.refreshBroadcaster(broadcasterId));
        });
    }

    private prepTableData() {
        if (this.channels) {
            const channels = [...this.channels.filter(c => !c.is_hidden)];
            this.rawChannelsBS$.next(channels);

            this.getAllChannelsBroadcasters();
            this.channels.forEach(async channel => {
                const broadcasterId = this.getServerBroadcasterId(channel);
                if (!channel._frontData.active_broadcaster && !!broadcasterId) {
                    channel._frontData.active_broadcaster = this.broadcasterService.getCachedBroadcaster(broadcasterId);
                }
            });
        }
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.channelsSubscription.unsubscribe();
        this.splitterPositionSubscription.unsubscribe();
        this.stopChannelsRefresh();
    }

    resizing(isResizing: boolean) {
        this.isResizing = isResizing;
    }

    onPagination(channels: Array<ChannelTypes>) {
        this.currentPageAdaptiveIDs = channels.filter(c => c.adaptive).map(c => c.id);
        this.currentPageDeliveryIDs = channels.filter(c => c.delivery).map(c => c.id);
        this.currentPageFlowIDs = channels.filter(c => c.mediaconnect).map(c => c.id);
        this.currentPageMediaLiveIDs = channels.filter(c => c.medialive).map(c => c.id);
        this.currentPageFailoverIDs = channels.filter(c => c.failover).map(c => c.id);
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    selectRow = (channel: ChannelTypes): void => {
        this.selectedChannel = channel;

        if (channel.mediaconnect) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.mediaconnect, channel.name)
            );
        } else if (channel.adaptive) {
            if (channel.is_transcoding)
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.transcoded, channel.name)
                );
            else
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.adaptive, channel.name)
                );
        } else if (channel.delivery) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.delivery, channel.name)
            );
        } else if (channel.medialive) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.medialive, channel.name)
            );
        } else if (channel.failover) {
            this.router.navigate(
                urlBuilder.getRegularChannelUrl(channel.id, Constants.urls.channelTypes.failover, channel.name)
            );
        }
    };

    async refresh() {
        if (this.isInsightRoute) return;

        this.refreshing = true;

        const adaptiveChannelsRefresh = firstValueFrom(
            this.cs.refreshAdaptiveChannels(
                this.currentPageAdaptiveIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.adaptive || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const deliveryChannelsRefresh = firstValueFrom(
            this.cs.refreshDeliveryChannels(
                this.currentPageDeliveryIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.delivery || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const mediaconnectFlowsRefresh = firstValueFrom(
            this.cs.refreshMediaConnectFlows(
                this.currentPageFlowIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.mediaconnect || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const mediaLiveChannelsRefresh = firstValueFrom(
            this.cs.refreshMediaLiveChannels(
                this.currentPageMediaLiveIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.medialive || id !== this.selectedChannel.id
                ),
                true
            )
        );
        const failoverChannelsRefresh = firstValueFrom(
            this.cs.refreshFailoverChannels(
                this.currentPageFailoverIDs?.filter(
                    id => !this.selectedChannel || !this.selectedChannel.failover || id !== this.selectedChannel.id
                ),
                true
            )
        );

        const channelRefresh =
            this.channelName && this.channelType && this.channelId
                ? this.cs.refreshChannel(this.selectedChannel, true).toPromise()
                : null;
        const channelComponentRefresh =
            this.channel && this.channel.component ? (this.channel.component as ChannelComponent)?.refresh() : null;

        await Promise.all([
            adaptiveChannelsRefresh,
            deliveryChannelsRefresh,
            mediaconnectFlowsRefresh,
            mediaLiveChannelsRefresh,
            failoverChannelsRefresh,
            channelRefresh,
            channelComponentRefresh
        ]);

        this.refreshing = false;
    }

    async multiAction(action: string, func: (channel: ChannelTypes) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "CHANNEL", this.selectedRows, func);
        if (!result.keepSelected) this.selectedRows = [];
        if (result.actionTaken)
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple channels");
    }

    async multiEnable() {
        let transcoded = false;
        this.selectedRows.forEach((channel: ChannelTypes) => {
            if (channel.adaptive && channel.is_transcoding) transcoded = true;
        });
        //
        if (transcoded) {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes, igt: boolean) => {
                    return this.cs.updateChannel(channel, {
                        is_enabled: true,
                        ignore_transcode_thresholds: igt
                    });
                },
                {
                    checkbox: "IGNORE_TRANSCODE_THRESHOLDS"
                }
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple channels");
        } else {
            const result = await this.modalService.confirmMultiple(
                "ENABLE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes) => this.cs.updateChannel(channel, { is_enabled: true })
            );
            if (!result.keepSelected) this.selectedRows = [];
            if (result.actionTaken)
                this.mixpanelService.sendEvent(this.translate.instant("ENABLE").toLowerCase() + " multiple channels");
        }
    }

    async multiDelete() {
        let mediaconnectFlow = false;
        this.selectedRows.forEach(channel => {
            if (channel.mediaconnect) mediaconnectFlow = true;
        });
        if (mediaconnectFlow) {
            const result = await this.modalService.confirmMultiple(
                "DELETE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes, awsRelease: boolean) => this.cs.deleteChannel(channel, awsRelease),
                {
                    checkbox: this.translate.instant(
                        "DELETE_MEDIACONNECT_FLOW_IN_ZEN_MASTER_LEAVE_IT_IN_AWS_MEDIACONNECT"
                    )
                    // deleteInput: true
                }
            );
            if (result.actionTaken) {
                this.mixpanelService.sendEvent(this.translate.instant("DELETE").toLowerCase() + " multiple channels");
                this.selectedRows = [];
            }
        } else {
            const result = await this.modalService.confirmMultiple(
                "DELETE",
                "CHANNEL",
                this.selectedRows,
                async (channel: ChannelTypes) => this.cs.deleteChannel(channel)
            );
            if (result.actionTaken) {
                this.mixpanelService.sendEvent(this.translate.instant("DELETE").toLowerCase() + " multiple channels");
                this.selectedRows = [];
            }
        }
    }

    async multiEdit() {
        await this.modalService.editMultiple("CHANNEL", this.selectedRows, async (channel: ChannelTypes, model) => {
            return this.cs.updateChannel(channel, model);
        });
    }

    multiToggleState(enable: boolean) {
        this.multiAction(enable ? "ENABLE" : "DISABLE", async (channel: ChannelTypes) =>
            this.cs.updateChannel(channel, { is_enabled: enable })
        );
    }

    multiToggleMute(mute: boolean): void {
        this.multiAction(mute ? "MUTE" : "UNMUTE", async (channel: ChannelTypes) =>
            this.cs.updateChannel(channel, {
                muted: mute,
                muted_until: null,
                flapping: null
            })
        );
    }

    startChannelsRefresh() {
        this.channelsRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopChannelsRefresh() {
        this.channelsRefreshSubscription.unsubscribe();
    }

    onSelectedRowsChange(selectedRows: ChannelTypes[]) {
        this.multiSelectDisasterRecovery = false;

        const isSelectionIncludeMediaChannels = Boolean(
            selectedRows.find(row => isMediaConnect(row) || isMediaLive(row))
        );

        const isSelectionDRable = Boolean(
            selectedRows.find(
                row =>
                    this.cs.getDisasterRecoveryState(row) !== RecoveryState.none &&
                    (row.delivery || row.adaptive || row.failover)
            )
        );

        this.multiSelectDisasterRecovery = isSelectionDRable && !isSelectionIncludeMediaChannels;
    }

    disasterRecoveryClick() {
        const modal = this.ngbModal.open(DisasterRecoveryDialogComponent, {
            backdrop: "static",
            centered: true,
            size: "lg"
        });
        modal.componentInstance.objects = this.selectedRows
            .filter(row => !row.mediaconnect)
            .map(s => ({
                id: s.id,
                type: s.failover ? "failover_channel" : s.delivery ? "delivery_channel" : "adaptive_channel"
            }));
    }
}
