import { Component, OnInit, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";

import { Constants } from "src/app/constants/constants";
import { Tag } from "src/app/models/shared";
import { TitleService } from "../../../services/title.service";
import { TitleCasePipe } from "@angular/common";
import { SharedService } from "src/app/services/shared.service";

@Component({
    selector: "app-channel-form",
    templateUrl: "./channel-form.component.html",
    styleUrls: ["./channel-form.component.scss"],
    providers: [TitleCasePipe]
})
export class ChannelFormComponent implements OnInit {
    tagsLoaded = false;
    showMoreOptions = false;

    cluster: string = null;
    name: string = null;
    type: string = null;

    adaptiveResourceTags: Tag[] = [];
    deliveryResourceTags: Tag[] = [];
    mediaconnectResourceTags: Tag[] = [];
    medialiveResourceTags: Tag[] = [];
    failoverResourceTags: Tag[] = [];

    constants = Constants;
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private titleService = inject(TitleService);
    private location = inject(Location);
    private sharedService = inject(SharedService);

    async ngOnInit() {
        this.titleService.setTitle("CHANNEL");

        const params = this.route.snapshot.params;
        this.type = params.type;
        this.cluster = params.cluster;
        this.name = params.name;

        // resourceTags
        const adaptiveTags = this.sharedService.getResourceTagsByType("adaptive_channel").toPromise();
        const deliveryTags = this.sharedService.getResourceTagsByType("delivery_channel").toPromise();
        const mediaconnectTags = this.sharedService.getResourceTagsByType("mediaconnect_flows").toPromise();
        const medialiveTags = this.sharedService.getResourceTagsByType("medialive_channels").toPromise();
        const failoverTags = this.sharedService.getResourceTagsByType("failover_channel").toPromise();

        await Promise.all([adaptiveTags, deliveryTags, mediaconnectTags, medialiveTags, failoverTags]);

        adaptiveTags.then((tags: Tag[]) => {
            this.adaptiveResourceTags = tags;
        });

        deliveryTags.then((tags: Tag[]) => {
            this.deliveryResourceTags = tags;
        });

        mediaconnectTags.then((tags: Tag[]) => {
            this.mediaconnectResourceTags = tags;
        });

        medialiveTags.then((tags: Tag[]) => {
            this.medialiveResourceTags = tags;
        });

        failoverTags.then((tags: Tag[]) => {
            this.failoverResourceTags = tags;
        });

        this.tagsLoaded = true;
    }

    goto(type: string) {
        switch (type) {
            case "adaptive":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.adaptive,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.adaptive, "new"]);
            case "transcode":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.transcoded,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.transcoded,
                        "new"
                    ]);
            case "pass-through":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.delivery,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.delivery, "new"]);
            case "mediaconnect":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.mediaconnect,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.mediaconnect,
                        "new"
                    ]);
            case "medialive":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.medialive,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.medialive,
                        "new"
                    ]);
            case "failover":
                if (this.type && this.cluster && this.name)
                    return this.router.navigate([
                        Constants.urls.channels,
                        Constants.urls.channelTypes.failover,
                        "new",
                        this.type,
                        this.cluster,
                        this.name
                    ]);
                else
                    return this.router.navigate([Constants.urls.channels, Constants.urls.channelTypes.failover, "new"]);
        }
    }

    cancel() {
        this.location.back();
    }
}
